
.FullScreen__Container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    margin-top: -8vh;
}

.FullScreen__Title {
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.115242px;
    text-align: center;
    color: #2D4084;

    margin: 3.45vh 66px 0;
}

.FullScreen__Description {
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.115242px;
    text-align: center;
    color: #2D4084;

    margin: 1.6vh 36px 0;
}

.FullScreen__Subdescription {
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.115242px;
    color: #2D4084;

    margin: 1.97vh 36px 0;
}


#p0 .CustomButton, #p1 .CustomButton, #p2 .CustomButton, #p3 .CustomButton, #p4 .CustomButton, #p5 .CustomButton, #p6 .CustomButton, #p7 .CustomButton {
    height: auto;
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: 500px;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.3px;
    color: #FFFFFF;

    background: url('/vercel/path0/src/img/result_bg.png');
    
    border: 2px solid #66B4FF;
    filter: drop-shadow(0px 3px 29px rgba(0, 0, 0, 0.09));
    border-radius: 25px;
    padding: 20.5px;

    position: fixed;
    bottom: 18.84vh;
    margin: 0 auto;
    max-width: 78vw;
}




#p0 .CustomButton span, #p1 .CustomButton span, #p2 .CustomButton span, #p3 .CustomButton span, #p4 .CustomButton span, #p5 .CustomButton span, #p6 .CustomButton span, #p7 .CustomButton span {
    max-width: 53vw;
}

#p7 .CustomButton, #p6 .CustomButton {
    position: initial;
}

.ModalCard__actions .CustomButton, #vk_time .CustomButton {
    height: auto;
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.3px;
    color: #FFFFFF;

    border: 2px solid #66B4FF;
    filter: drop-shadow(0px 3px 29px rgba(0, 0, 0, 0.09));
    border-radius: 25px;
    padding: 20.5px 0;
    margin: 0 auto;
}

#vk_time .CustomButton {
    font-size: 1.97vh;
}

.ButtonsContainer {
    position: fixed;
    bottom: 5.11vh;

    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.Background {
    z-index: -1;
    position: fixed;
    -webkit-transform: translate(-116.53vw, -17.49vh);
    -moz-transform: translate(-116.53vw, -17.49vh);
    -ms-transform: translate(-116.53vw, -17.49vh);
    -o-transform: translate(-116.53vw, -17.49vh);
    transform: translate(-116.53vw, -17.49vh);
}

.PercentTitle {
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 96px;
    line-height: 124px;
    text-align: center;
    letter-spacing: -0.115242px;
    color: #BBD1F4;

    position: fixed;
    bottom: 17.12vh;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
}

.Loading {
    position: fixed;
    top: 0;

    -webkit-transform: translate(-65.33vw, 31.4vh);
    -moz-transform: translate(-65.33vw, 31.4vh);
    -ms-transform: translate(-65.33vw, 31.4vh);
    -o-transform: translate(-65.33vw, 31.4vh);
    transform: translate(-65.33vw, 31.4vh);
}

.PosterHeader {
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 2.22vh;
    line-height: 23px;
    letter-spacing: -0.3px;
    color: #2D4084;

    max-width: 72.8vw;

    margin-top: calc(var(--safe-area-inset-top) + var(--panelheader_height_ios));
    margin-left: 28px;
    z-index: 1;
}

.PosterStats {
    z-index: 0;
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

    height: 73vh;
    top: 33.5vh;
}

#p7 .FullScreen__Container {
    margin-top: 0;
}

#p5 .TextHeader {
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.115242px;
    color: #2D4084;

    margin-top: 22.54vh;
}

#p5 .PriceContainer {
    width: 60.8vw;
    min-height: 14.53vh;
    background: url('../img/result_bg.png');

    padding: 35px 54px 20px 34px;

    margin: 3.69vh auto;
    border-radius: 26.9341px;
    -webkit-box-shadow: inset 0 0 0 3.10778px rgba(255, 255, 255, .26), inset 0 0 0 2px #66B4FF, 0 16.5749px 32.4591px rgba(102, 173, 246, 0.47);
    -moz-box-shadow: inset 0 0 0 3.10778px rgba(255, 255, 255, .26), inset 0 0 0 2px #66B4FF, 0 16.5749px 32.4591px rgba(102, 173, 246, 0.47);
    box-shadow: inset 0 0 0 3.10778px rgba(255, 255, 255, .26), inset 0 0 0 2px #66B4FF, 0 16.5749px 32.4591px rgba(102, 173, 246, 0.47);
}

#p5 .PriceContainer > :first-child {
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 25px;
    color: #FFFFFF;
}

#p5 .PriceContainer > :last-child {
    display: flex;
    align-items: baseline;
}

#p5 .PriceContainer > :last-child > :first-child {
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 72px;
    line-height: 93px;
    color: #FFFFFF;
}

#p5 .PriceContainer > :last-child > :last-child {
    font-family: TT Firs Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #2D4084;

    margin-left: 11px;
}

#p5 .CustomButton {
    position: fixed;
    bottom: 12.56vh;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    
}
