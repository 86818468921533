.CustomButton {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 14px;

    text-align: center;
    letter-spacing: -0.25px;

    color: #574039;

    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;

    height: 8.74vh;
    width: 85vw;

    background: #FFFFFF;
    backdrop-filter: blur(77px);
}